import React from "react"

const Footer = () => (
  <footer id="footer-container">
    <div id="footer">
      <address id="social">
        <a
          className="social-item"
          id="github"
          href="https://github.com/swgoodman"
          target="_blank"
          rel="noreferrer"
        >
          github
        </a>
        <a
          className="social-item"
          href="https://twitter.com/spencerwgoodman"
          target="_blank"
          rel="noreferrer"
        >
          twitter
        </a>
        <a className="social-item" href="mailto:spencerwgoodman@icloud.com">
          email
        </a>
      </address>
      <p id="whereami">
        built in{" "}
        <a
          id="chicago"
          className="social-item"
          href="https://www.youtube.com/watch?v=tWX3El-slpY"
          target="_blank"
          rel="noreferrer"
        >
          Chicago
        </a>{" "}
        ☁️
      </p>
    </div>
  </footer>
)

export default Footer
