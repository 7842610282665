import React from "react"
import Header from "./header"
import Goku from "../assets/images/goku.gif"

const Hero = ({ colorMode, setColorMode, theme }) => {
  return (
    <section id="hero">
      <Header />
      <div id="hero-body-container">
        <div id="hero-body">
          <div id="img-container">
            <div id="crop">
              <img src={Goku} id="goku" alt="goku" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
